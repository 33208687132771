import { Organization, organizationUuid } from '@/models/Organization';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { DateTime } from 'luxon';
import { PlanningWeek } from '@/models/PlanningWeek';
import MiSettings from '@/components/MiSettings/MiSettings.vue';
import { tcmgSetting } from '@/components/MiSettings/MiSettings';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { SettingKey } from '@/models/Setting';

@Component<Settings>({
  components: {
    MiSettings,
  },
})
export default class Settings extends Vue {
  public $pageTitle = 'Instellingen';

  protected isLoading = true;

  protected menu = false;

  // Organizations
  protected organizations: Organization[] | null = null;

  protected activeOrganization = 'general';

  protected generalSettings: tcmgSetting[] = [
    {
      key: 'planning_emailbox_admins',
      value: '',
      label: 'E-mailadres Planning',
      description: 'Op dit adres worden notificaties verzonden die bedoelt zijn voor jullie planningbeheerders. Voorbeeld: planning@schadedoormijnbouw.nl',
      type: 'text-field',
      visible: true,
    },
    {
      key: 'planning_experts_can_access_availability',
      value: '',
      label: 'Zelf beschikbaarheid invullen',
      description: 'Met deze optie krijgen alle deskundigen/zaakbegeleiders de mogelijkheid om zelf hun beschikbaarheid op te geven.',
      type: 'switch',
      visible: true,
    },
    {
      key: 'planning_experts_notifiable',
      value: '',
      label: 'E-mail deskundigen/zaakbegeleiders',
      description: 'Met deze optie krijgen alle deskundigen/zaakbegeleiders een e-mail wanneer de beschikbarheid wordt opgevraagd.',
      type: 'switch',
      visible: true,
    },
    {
      key: 'planning_active_week',
      value: '',
      label: 'Actieve week',
      description: 'Vul hier een willekeurige dag van de actieve week in zodat de kalendars standaard op deze datum worden ingeladen.',
      type: 'date',
      menu: false,
      visible: true,
    },
    {
      key: 'planning_schedule_lock_date_users',
      value: '',
      label: 'Beschikbaarheid blokkeren gebruikers',
      description: 'Beschikbaarheid blokkeren voor alle gebruikers (maar niet voor Planningbeheerders) tot en met deze datum.',
      type: 'date',
      menu: false,
      visible: ! this.$store.state.isServiceOrganization,
    },
    {
      key: 'planning_schedule_lock_date_admins',
      value: '',
      label: 'Beschikbaarheid blokkeren beheerders',
      description: 'Beschikbaarheid blokkeren volledig blokkeren (dus incl. de Planningbeheerders) tot en met deze datum.',
      type: 'date',
      menu: false,
      visible: true,
    },
    {
      key: SettingKey.PLANNING_VISIBLE_END_DATE_FOR_TCMG,
      value: '',
      label: 'Vrijgegeven beschikbaar aan IMG',
      description: 'Alle beschikbaarheid tot en met deze datum zijn zichtbaar voor de afdeling planning.',
      type: 'date',
      menu: false,
      visible: true,
    },
    {
      key: 'planning_plan_window_start',
      value: '',
      label: 'Planbare periode - start',
      description: 'De afdeling planning kan geen afspraken inplannen VOOR deze datum.',
      type: 'date',
      menu: false,
      visible: this.$store.state.isServiceOrganization,
    },
    {
      key: 'planning_plan_window_end',
      value: '',
      label: 'Planbare periode - eind',
      description: 'De afdeling planning kan geen afspraken inplannen NA deze datum.',
      type: 'date',
      menu: false,
      visible: this.$store.state.isServiceOrganization,
    },
  ];

  protected get expertSettings(): tcmgSetting[] {
    return [
      {
        key: 'max_visit_deskundige',
        value: '',
        label: `Daglimiet planning - ${this.activeOrganization === organizationUuid?.img ? 'IMG Deskundige (Zaakbegeleiders)' : 'Deskundige'}`,
        description: 'Voer hier het maximale aantal afspraken wat deze gebruikersgroep mag lopen per dag in.',
        type: 'text-field',
        menu: false,
        visible: this.$store.state.isServiceOrganization,
      },
      {
        key: 'max_visit_opnemer',
        value: '',
        label: `Daglimiet planning - ${this.activeOrganization === organizationUuid?.img ? 'IMG opnemer (Zaakbegeleiders)' : 'Opnemer'}`,
        description: 'Voer hier het maximale aantal afspraken wat deze gebruikersgroep mag lopen per dag in.',
        type: 'text-field',
        menu: false,
        visible: this.$store.state.isServiceOrganization,
      },
      {
        key: 'max_visit_type_default',
        value: '',
        label: 'Daglimiet planning - Schade-opname',
        description: 'Voer hier het maximale aantal afspraken wat deze gebruikersgroep mag lopen per dag in.',
        type: 'text-field',
        menu: false,
        visible: false,
      },
      {
        key: 'max_visit_type_vervolgopname',
        value: '',
        label: 'Daglimiet planning - Vervolgopname',
        description: 'Voer hier het maximale aantal afspraken wat deze gebruikersgroep mag lopen per dag in.',
        type: 'text-field',
        menu: false,
        visible: false,
      },
      {
        key: 'max_visit_type_nader_onderzoek',
        value: '',
        label: 'Daglimiet planning - Nader onderzoek',
        description: 'Voer hier het maximale aantal afspraken wat deze gebruikersgroep mag lopen per dag in.',
        type: 'text-field',
        menu: false,
        visible: false,
      },
      {
        key: 'max_visit_type_aanvullend_onderzoek',
        value: '',
        label: 'Daglimiet planning - Aanvullend onderzoek',
        description: 'Voer hier het maximale aantal afspraken wat deze gebruikersgroep mag lopen per dag in.',
        type: 'text-field',
        menu: false,
        visible: false,
      },
      {
        key: 'max_visit_type_heropname',
        value: '',
        label: 'Daglimiet planning - Heropname',
        description: 'Voer hier het maximale aantal afspraken wat deze gebruikersgroep mag lopen per dag in.',
        type: 'text-field',
        menu: false,
        visible: false,
      },
      {
        key: 'max_visit_type_hoorzitting',
        value: '',
        label: 'Daglimiet planning - Hoorzitting',
        description: 'Voer hier het maximale aantal afspraken wat deze gebruikersgroep mag lopen per dag in.',
        type: 'text-field',
        menu: false,
        visible: false,
      },
      {
        key: 'max_visit_type_second_opinion',
        value: '',
        label: 'Daglimiet planning - Second Opinion',
        description: 'Voer hier het maximale aantal afspraken wat deze gebruikersgroep mag lopen per dag in.',
        type: 'text-field',
        menu: false,
        visible: false,
      },
      {
        key: 'max_visit_type_opname_op_afstand',
        value: '',
        label: 'Daglimiet planning - Opname op afstand',
        description: 'Voer hier het maximale aantal afspraken wat deze gebruikersgroep mag lopen per dag in.',
        type: 'text-field',
        menu: false,
        visible: false,
      },
      {
        key: 'max_visit_type_physical',
        value: '',
        label: 'Daglimiet planning - Fysieke afspraak',
        description: 'Voer hier het maximale aantal afspraken wat deze gebruikersgroep mag lopen per dag in.',
        type: 'text-field',
        menu: false,
        visible: this.$store.state.isServiceOrganization && this.hasAppointmentSubdivision,
      },
      {
        key: 'max_visit_type_webex',
        value: '',
        label: 'Daglimiet planning - Webex afspraak',
        description: 'Voer hier het maximale aantal afspraken wat deze gebruikersgroep mag lopen per dag in.',
        type: 'text-field',
        menu: false,
        visible: this.$store.state.isServiceOrganization && this.hasAppointmentSubdivision,
      },
      {
        key: 'max_visit_type_office',
        value: '',
        label: 'Daglimiet planning - Kantoor',
        description: 'Voer hier het maximale aantal afspraken wat deze gebruikersgroep mag lopen per dag in.',
        type: 'text-field',
        menu: false,
        visible: this.$store.state.isServiceOrganization && this.hasAppointmentSubdivision,
      },
      {
        key: 'max_visit_type_call',
        value: '',
        label: 'Daglimiet planning - Bel afspraak',
        description: 'Voer hier het maximale aantal afspraken wat deze gebruikersgroep mag lopen per dag in.',
        type: 'text-field',
        menu: false,
        visible: this.$store.state.isServiceOrganization && this.hasAppointmentSubdivision,
      },
    ];
  }

  protected get hasAppointmentSubdivision(): boolean {
    return this.activeOrganization === organizationUuid?.img;
  }

  protected date: string | null = null;

  protected requested_planning_notifiable = false;

  protected lastResquestedPlanningWeek: PlanningWeek | null = null;

  public mounted() {
    this.emitBreadcrumb();
    this.initialize();
  }

  protected async initialize() {
    this.isLoading = true;
    await this.getOrganizations();
    this.isLoading = false;
  }

  protected async getOrganizations() {
    if (this.$store.state.isServiceOrganization) {
      this.organizations = await new Organization()
        .sort('created_at', 'ASC')
        .all()
        .catch((error: AxiosError) => {
          ErrorHandler.network(error);
        });
      if (this.organizations && ! this.activeOrganization) {
        this.activeOrganization = this.organizations[0].id || '';
      }
    } else {
      this.activeOrganization = 'general';
    }
  }

  protected get lastRequestedWeekNumber() {
    if (! this.lastResquestedPlanningWeek || ! this.lastResquestedPlanningWeek.starts_at) {
      return '';
    }

    return DateTime.fromSQL(this.lastResquestedPlanningWeek.starts_at).weekNumber;
  }

  protected get lastRequestedStartDate() {
    if (! this.lastResquestedPlanningWeek || ! this.lastResquestedPlanningWeek.starts_at) {
      return '';
    }

    return DateTime.fromSQL(this.lastResquestedPlanningWeek.starts_at).toFormat('dd-LL-yyyy');
  }

  protected get lastRequestedEndDate() {
    if (! this.lastResquestedPlanningWeek || ! this.lastResquestedPlanningWeek.ends_at) {
      return '';
    }

    return DateTime.fromSQL(this.lastResquestedPlanningWeek.ends_at).toFormat('dd-LL-yyyy');
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Instellingen' },
        ],
      });
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}
