var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Instellingen")
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", md12: "" } },
            [
              !_vm.organizations && _vm.$store.state.isServiceOrganization
                ? _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("LoaderCard", {
                            attrs: { flat: "", type: "spinner--center" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-tabs",
                {
                  attrs: { "slider-color": "secondary" },
                  model: {
                    value: _vm.activeOrganization,
                    callback: function($$v) {
                      _vm.activeOrganization = $$v
                    },
                    expression: "activeOrganization"
                  }
                },
                [
                  _c(
                    "v-tab",
                    {
                      staticClass: "tab__filled",
                      class: { active: _vm.activeOrganization === "general" },
                      attrs: { href: "#general", disabled: _vm.isLoading }
                    },
                    [_vm._v(" Algemeen ")]
                  ),
                  _vm._l(_vm.organizations, function(organization, index) {
                    return [
                      _c(
                        "v-tab",
                        {
                          key: "termijn-tab-" + index,
                          staticClass: "tab__filled",
                          class: {
                            active: _vm.activeOrganization === organization.id
                          },
                          attrs: {
                            href: "#" + organization.id,
                            disabled: _vm.isLoading
                          }
                        },
                        [_vm._v(" " + _vm._s(organization.name) + " ")]
                      )
                    ]
                  }),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.activeOrganization,
                        callback: function($$v) {
                          _vm.activeOrganization = $$v
                        },
                        expression: "activeOrganization"
                      }
                    },
                    [
                      _c(
                        "v-tab-item",
                        {
                          attrs: {
                            lazy: true,
                            transition: false,
                            "reverse-transition": false,
                            value: "general"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "elementPanel elementPanel--spaced fill-height"
                            },
                            [
                              _c("MiSettings", {
                                attrs: { settings: _vm.generalSettings }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._l(_vm.organizations, function(organization) {
                        return [
                          _c(
                            "v-tab-item",
                            {
                              key: "termijn-tab-content-" + organization.id,
                              attrs: {
                                lazy: true,
                                transition: false,
                                "reverse-transition": false,
                                value: organization.id
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "elementPanel elementPanel--spaced fill-height"
                                },
                                [
                                  _c("MiSettings", {
                                    attrs: {
                                      settings: _vm.expertSettings,
                                      dmz: organization.id
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }